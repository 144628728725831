import { FC } from 'react'

import {
  FindWebhooksGridQuery,
  FindWebhooksGridQueryVariables,
} from 'types/graphql'

import type { CellFailureProps, CellSuccessProps } from '@redwoodjs/web'

import Spinner from 'src/components/Library/Loading'
import WebhooksGrid from 'src/components/Webhooks/WebhooksGrid'

export const QUERY = gql`
  query FindWebhooksGridQuery {
    clients {
      id
      name
    }
    jobTypes: getQueuerJobTypes
    webhooks {
      id
      uuid
      name
      createdAt
      createdBy
      client {
        id
        name
      }
      jobType
      lastTriggered
      isActive
    }
  }
`

type SuccessProps = CellSuccessProps<
  FindWebhooksGridQuery,
  FindWebhooksGridQueryVariables
>

export type Client = {
  id: number
  name: string
}

export type WebhooksGridCellProps = {
  clients: Client[]
  jobTypes: FindWebhooksGridQuery['jobTypes']
  webhooks: FindWebhooksGridQuery['webhooks']
}

export const Loading = () => <Spinner />

export const Empty = ({ clients, jobTypes }) => (
  <WebhooksGrid webhooks={[]} clients={clients} jobTypes={jobTypes} />
)

export const Failure = ({ error }: CellFailureProps) => (
  <div style={{ color: 'red' }}>Error: {error?.message}</div>
)

export const Success: FC<SuccessProps> = ({ webhooks, clients, jobTypes }) => {
  return (
    <WebhooksGrid webhooks={webhooks} clients={clients} jobTypes={jobTypes} />
  )
}
