import { FC, useEffect, useState } from 'react'

import { ClipboardIcon } from '@heroicons/react/24/outline'
import { Drawer, InputAdornment, TextField, Tooltip } from '@mui/material'
import { PROD_CLIENT } from 'api/src/common/enums'
import { getHubUrl } from 'api/src/common/utils'
import {
  CreateWebhook,
  CreateWebhookVariables,
  DeleteWebhook,
  DeleteWebhookVariables,
  JOB_TYPE,
  UpdateWebhook,
  UpdateWebhookVariables,
} from 'types/graphql'

import { useMutation } from '@redwoodjs/web'

import AutoComplete from 'src/components/Library/Autocomplete/Autocomplete'
import Button from 'src/components/Library/Button/Button'
import IconButton from 'src/components/Library/IconButton/IconButton'
import { RowData } from 'src/components/Webhooks/WebhooksGrid'
import { QUERY } from 'src/components/Webhooks/WebhooksGridCell'
import { Client } from 'src/components/Webhooks/WebhooksGridCell/WebhooksGridCell'

type WebhooksSettingsDrawerProps = {
  webhookSettingsOpen: boolean
  setWebhookSettingsOpen: (open: boolean) => void
  webhookData: RowData
  clients: Client[]
  jobTypes: JOB_TYPE[]
}

const WebhookSettingsDrawer: FC<WebhooksSettingsDrawerProps> = ({
  webhookSettingsOpen,
  setWebhookSettingsOpen,
  webhookData,
  clients,
  jobTypes,
}) => {
  enum Step {
    CREATE = 'create',
    EDIT = 'edit',
    DISPLAY = 'display',
  }

  const [step, setStep] = useState(Step.CREATE)
  const [webhook, setWebhook] = useState(webhookData)
  const [saveLoading, setSaveLoading] = useState(false)
  const [deleteLoading, setDeleteLoading] = useState(false)

  const [copiedUrl, setCopiedUrl] = useState(false)
  const [copiedSecret, setCopiedSecret] = useState(false)

  const sortedClients = [...clients].sort((a, b) => {
    if (a.id === PROD_CLIENT.STAFFLINK) {
      return -1
    }
    if (b.id === PROD_CLIENT.STAFFLINK) {
      return 1
    }
    return a.name.localeCompare(b.name)
  })

  const buildWebhookUrl = (uuid) => {
    const hubUrl = getHubUrl()
    const webhookUrl = `${hubUrl}/api/webhooks/${uuid}`
    return webhookUrl
  }

  const handleCopy = (type) => {
    if (type === 'url') {
      navigator.clipboard.writeText(buildWebhookUrl(webhook?.uuid))
      setCopiedUrl(true)
      setTimeout(() => setCopiedUrl(false), 2000)
    } else {
      navigator.clipboard.writeText(webhook?.secretKey)
      setCopiedSecret(true)
      setTimeout(() => setCopiedSecret(false), 2000)
    }
  }

  const handleClose = () => {
    setStep(Step.CREATE)
    setWebhook(null)
    setWebhookSettingsOpen(false)
  }

  useEffect(() => {
    if (webhookData?.id) {
      setStep(Step.EDIT)
      setWebhook(webhookData)
    } else {
      setStep(Step.CREATE)
      setWebhook(null)
    }
  }, [webhookData?.id, webhookData])

  const CREATE_WEBHOOK = gql`
    mutation CreateWebhook($input: CreateWebhookInput!) {
      createWebhook(input: $input) {
        id
        name
        client {
          id
          name
        }
        jobType
        secretKey
        uuid
      }
    }
  `

  const UPDATE_WEBHOOK = gql`
    mutation UpdateWebhook($id: Int!, $input: UpdateWebhookInput!) {
      updateWebhook(id: $id, input: $input) {
        id
        name
        client {
          id
          name
        }
        jobType
      }
    }
  `

  const DELETE_WEBHOOK = gql`
    mutation DeleteWebhook($id: Int!) {
      deleteWebhook(id: $id) {
        id
      }
    }
  `

  const [createWebhook] = useMutation<CreateWebhook, CreateWebhookVariables>(
    CREATE_WEBHOOK,
    {
      onCompleted: ({ createWebhook }) => {
        // @ts-expect-error type is modified by createSecure
        setWebhook(createWebhook)
        setStep(Step.DISPLAY)
        setSaveLoading(false)
      },
      awaitRefetchQueries: true,
      refetchQueries: [{ query: QUERY, fetchPolicy: 'network-only' }],
    },
  )

  const [updateWebhook] = useMutation<UpdateWebhook, UpdateWebhookVariables>(
    UPDATE_WEBHOOK,
    {
      onCompleted: () => {
        setSaveLoading(false)
        handleClose()
      },
      awaitRefetchQueries: true,
      refetchQueries: [{ query: QUERY, fetchPolicy: 'network-only' }],
    },
  )

  const [deleteWebhook] = useMutation<DeleteWebhook, DeleteWebhookVariables>(
    DELETE_WEBHOOK,
    {
      onCompleted: () => {
        setDeleteLoading(false)
        handleClose()
      },
      awaitRefetchQueries: true,
      refetchQueries: [{ query: QUERY, fetchPolicy: 'network-only' }],
    },
  )

  const handleCreate = () => {
    setSaveLoading(true)
    createWebhook({
      variables: {
        input: {
          name: webhook.name,
          clientId: webhook.client.id,
          jobType: webhook.jobType,
        },
      },
    })
  }

  const handleUpdate = () => {
    setSaveLoading(true)
    updateWebhook({
      variables: {
        id: webhook.id,
        input: {
          name: webhook.name,
        },
      },
    })
  }

  const handleDelete = (webhookId) => {
    setDeleteLoading(true)
    deleteWebhook({
      variables: {
        id: webhookId,
      },
    })
  }

  return (
    <Drawer
      anchor="right"
      open={webhookSettingsOpen}
      PaperProps={{
        style: {
          width: '600px',
        },
      }}
    >
      <div className="flex flex-col gap-4 w-full h-full p-4">
        <h1 className="text-2xl font-bold mb-4">
          {step === 'edit' ? 'Edit Webhook' : 'Create Webhook'}
        </h1>
        <TextField
          label="Name"
          value={webhook?.name || ''}
          onChange={(e) =>
            setWebhook((prev) => ({ ...prev, name: e.target.value }))
          }
          disabled={step === 'display'}
        />
        <AutoComplete
          value={webhook ? webhook.client : null}
          options={sortedClients}
          onChange={(_, newValue) =>
            setWebhook((prev) => ({ ...prev, client: newValue }))
          }
          getOptionLabel={(option) => option.name}
          renderInput={(params) => <TextField {...params} label="Client" />}
          disabled={step !== 'create'}
          disableClearable
        />
        <AutoComplete
          value={webhook ? webhook.jobType : null}
          options={jobTypes}
          onChange={(_, newValue) =>
            setWebhook((prev) => ({ ...prev, jobType: newValue }))
          }
          renderInput={(params) => <TextField {...params} label="Job Type" />}
          disabled={step !== 'create'}
          disableClearable
        />

        {step !== 'display' && (
          <div className="flex gap-2 mt-4">
            {step === 'edit' && (
              <Button
                className="mr-auto w-1/4"
                color={'error'}
                onClick={() => {
                  handleDelete(webhook?.id)
                }}
                loading={deleteLoading}
                disabled={saveLoading}
              >
                Delete
              </Button>
            )}
            <Button
              variant="text"
              className="ml-auto w-1/4"
              onClick={() => handleClose()}
              disabled={saveLoading || deleteLoading}
            >
              Cancel
            </Button>
            <Button
              onClick={step === 'create' ? handleCreate : handleUpdate}
              className="w-1/4"
              loading={saveLoading}
              disabled={
                deleteLoading ||
                !webhook?.name ||
                !webhook?.client ||
                !webhook?.jobType
              }
            >
              {step === 'create' ? 'Create' : 'Save'}
            </Button>
          </div>
        )}

        {step === 'display' && (
          <>
            <TextField
              label="URL"
              value={buildWebhookUrl(webhook?.uuid)}
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <InputAdornment position="end">
                    <Tooltip
                      title={copiedUrl ? 'Copied!' : 'Copy URL'}
                      placement="top"
                    >
                      <IconButton
                        onClick={() => handleCopy('url')}
                        size="small"
                      >
                        <ClipboardIcon className="w-6 h-6" />
                      </IconButton>
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
              className="w-full bg-white rounded"
              variant="outlined"
            />
            <div>
              <TextField
                label="Secret Key"
                value={webhook?.secretKey}
                InputProps={{
                  readOnly: true,
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip
                        title={copiedSecret ? 'Copied!' : 'Copy Secret Key'}
                        placement="top"
                      >
                        <IconButton
                          onClick={() => handleCopy('secret')}
                          size="small"
                        >
                          <ClipboardIcon className="w-6 h-6" />
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
                className="w-full bg-white rounded"
                variant="outlined"
              />
              <p className="text-gray-500 text-xs mt-4">
                This key will only be displayed once. Please ensure you have
                copied it to a safe and secure location.
              </p>
            </div>
            <div className="flex gap-2 mt-4">
              <Button onClick={handleClose}>I have copied the key</Button>
            </div>
          </>
        )}
      </div>
    </Drawer>
  )
}

export default WebhookSettingsDrawer
