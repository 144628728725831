import { Metadata } from '@redwoodjs/web'

import PageHeader from 'src/components/PageHeader/PageHeader'
import WebhooksGridCell from 'src/components/Webhooks/WebhooksGridCell'

const WebhooksPage = () => {
  return (
    <>
      <Metadata title="Webhooks" description="Webhooks page" />

      <PageHeader title={'Webhooks'} parentDataTestId="webhooks-page-header" />
      <WebhooksGridCell />
    </>
  )
}

export default WebhooksPage
