import { FC, useEffect, useMemo, useState } from 'react'

import { themeQuartz } from '@ag-grid-community/theming'
import { LinkIcon, PencilIcon, PlusIcon } from '@heroicons/react/24/solid'
import { ColDef } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import { getHubUrl } from 'api/src/common/utils'
import { JOB_TYPE, UpdateWebhook, UpdateWebhookVariables } from 'types/graphql'

import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import Button from 'src/components/Library/Button/Button'
import IconButton from 'src/components/Library/IconButton/IconButton'
import { SearchField } from 'src/components/Library/SearchField'
import Switch from 'src/components/Library/Switch/Switch'
import WebhookSettingsDrawer from 'src/components/Webhooks/WebhookSettingsDrawer/WebhookSettingsDrawer'
import { QUERY } from 'src/components/Webhooks/WebhooksGridCell'
import type { WebhooksGridCellProps } from 'src/components/Webhooks/WebhooksGridCell/WebhooksGridCell'
import { Client } from 'src/components/Webhooks/WebhooksGridCell/WebhooksGridCell'

import 'src/styles/recordGrid.css'

export type RowData = {
  id: number
  name: string
  clientId?: number | null
  client: Client | null
  jobType: JOB_TYPE
  lastTriggered?: string | null
  isActive?: boolean | null
  secretKey: string | null
  uuid: string | null
}

const webhooksGridTheme = themeQuartz.withParams({
  accentColor: '#6366F1',
  browserColorScheme: 'light',
  headerFontSize: 14,
  sidePanelBorder: true,
  wrapperBorder: true,
})

const WebhooksGrid: FC<WebhooksGridCellProps> = ({
  webhooks,
  clients,
  jobTypes,
}) => {
  const formatData = (webhooks) => {
    return webhooks.map((webhook) => {
      return {
        id: webhook.id,
        uuid: webhook.uuid,
        name: webhook.name,
        client: webhook.client,
        jobType: webhook.jobType,
        lastTriggered: webhook.lastTriggered,
        isActive: webhook.isActive,
      }
    })
  }
  const [rowData, setRowData] = useState<RowData[]>(formatData(webhooks))
  const [searchVal, setSearchVal] = useState('')
  const [webhookSettingsOpen, setWebhookSettingsOpen] = useState(false)
  const [selectedWebhook, setSelectedWebhook] = useState(null)

  useEffect(() => {
    if (!webhookSettingsOpen) {
      setSelectedWebhook(null)
    }
  }, [webhookSettingsOpen])

  useEffect(() => {
    setRowData(formatData(webhooks))
  }, [webhooks])

  const customActionsComponent = ({ data }) => {
    return (
      <div className="flex justify-center">
        <Switch
          checked={data.isActive}
          onChange={(e) => {
            setRowData(
              rowData.map((row) => {
                if (row.id === data.id) {
                  return { ...row, isActive: e.target.checked }
                }
                return row
              }),
            )
            updateWebhookIsActive({
              variables: {
                id: data.id,
                input: { isActive: e.target.checked },
              },
            })
          }}
        />

        <IconButton
          className="text-gray-500"
          onClick={() => {
            setSelectedWebhook(data)
            setWebhookSettingsOpen(true)
          }}
        >
          <PencilIcon className="w-5 h-5" />
        </IconButton>

        <IconButton
          className="text-blue-500"
          onClick={() => {
            // copy to clipboard
            const hubUrl = getHubUrl()
            const webhookUrl = `${hubUrl}/api/webhooks/${data.uuid}`
            try {
              navigator.clipboard.writeText(webhookUrl)
              toast.success('Copied URL to clipboard')
            } catch (err) {
              toast.error('Failed to copy to clipboard')
            }
          }}
        >
          <LinkIcon className="w-5 h-5" />
        </IconButton>
      </div>
    )
  }

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      filter: true,
    }
  }, [])

  const [colDefs] = useState<ColDef[]>([
    { field: 'id', width: 100, headerName: 'ID', sort: 'desc' },
    { field: 'uuid', flex: 1, headerName: 'UUID' },
    { field: 'name', flex: 1, headerName: 'Name' },
    {
      field: 'clientId',
      headerName: 'Client ID',
      width: 120,
      valueGetter: (params) => {
        return params.data.client ? params.data.client.id : ''
      },
    },
    {
      field: 'client',
      flex: 1,
      valueGetter: (params) => {
        return params.data.client ? params.data.client.name : ''
      },
    },
    { field: 'jobType', flex: 1 },
    {
      field: 'lastTriggered',
      headerName: 'Last Triggered (Local)',
      filter: 'agDateColumnFilter',
      cellDataType: 'date',
      valueGetter: (params) => {
        return params.data.lastTriggered
          ? new Date(params.data.lastTriggered)
          : ''
      },
      valueFormatter: (params) =>
        params.value ? params.value.toLocaleString() : '',
    },
    {
      field: 'isActive',
      headerName: 'Actions',
      cellRenderer: customActionsComponent,
      resizable: false,
    },
  ])

  const UPDATE_WEBHOOK_IS_ACTIVE = gql`
    mutation UpdateWebhookIsActive($id: Int!, $input: UpdateWebhookInput!) {
      updateWebhook(id: $id, input: $input) {
        id
      }
    }
  `

  const [updateWebhookIsActive] = useMutation<
    UpdateWebhook,
    UpdateWebhookVariables
  >(UPDATE_WEBHOOK_IS_ACTIVE, {
    onCompleted: () => {},
    awaitRefetchQueries: true,
    refetchQueries: [{ query: QUERY, fetchPolicy: 'network-only' }],
  })

  return (
    <>
      <div className="w-full h-5/6 p-4">
        <div className="flex gap-2 mb-2">
          <SearchField value={searchVal} onChange={(e) => setSearchVal(e)} />
          <Button
            className="w-48"
            startIcon={<PlusIcon className="w-5 h-5" />}
            onClick={() => setWebhookSettingsOpen(true)}
          >
            Create Webhook
          </Button>
        </div>
        <AgGridReact
          quickFilterText={searchVal}
          rowData={rowData}
          defaultColDef={defaultColDef}
          columnDefs={colDefs}
          onRowDoubleClicked={({ data }) => {
            setSelectedWebhook(data)
            setWebhookSettingsOpen(true)
          }}
          theme={webhooksGridTheme}
        />
      </div>
      <WebhookSettingsDrawer
        webhookSettingsOpen={webhookSettingsOpen}
        setWebhookSettingsOpen={setWebhookSettingsOpen}
        webhookData={selectedWebhook}
        clients={clients}
        jobTypes={jobTypes}
      />
    </>
  )
}

export default WebhooksGrid
